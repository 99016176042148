import { createStore } from 'vuex'

export default createStore({
    state: {
        sidebarVisible:  (window.innerWidth < 1200) ? false : true,
        sidebarColor: 'active bg-gradient-primary',
        sidebarBgColor: 'bg-gradient-dark',
        sidebarTextColor: 'text-white',
        bottomNavbarTextColor:'text-primary',
        language:'en',
    },
    mutations: {
        changeLanguage(state, value){
          state.language =  value;
        },
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible;
        },
        toggleSidebarColor(state, value) {
          state.sidebarColor = 'active ' + value;
        },
        toggleSidebarBg(state, value) {
          state.sidebarBgColor = value;
        },
        toggleSidebarText(state, value) {
          state.sidebarTextColor = value;
        },
    },
    actions: {},
    modules: {},
})