<template>
    <label class="mb-0 text-primary fw-bold">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <div :class="'input-group ' + classGroup_ + ' ' + focused + ' ' + (error_ ? 'is-invalid': '')">
        <textarea :type="type_" 
            :value="modelValue" 
            :class="'form-control bg-white ' + class_" 
            :placeholder="placeholder_" 
            :disabled="readonly_" 
            autocomplete="off" 
            @focus="focused = (!readonly_ ? 'focused is-focused': '')" 
            v-on:blur="modelValue ? '': (focused = '')"
            @input="$emit('update:modelValue',isForceUppercase ? $event.target.value.toUpperCase() : $event.target.value)"
            @keyup="$emit('keyup', $event)"></textarea>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
    </small>
</template>

<script>
export default {
    props: {
        classGroup_:    { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:         { type: String, default: '' },
        title_:         { type: String },
        type_:          { type: String, default: 'text' },
        placeholder_:   { type: String },
        readonly_:      { type: Boolean, default: false },
        required_:      { type: Boolean, default: false },
        error_:         { type: Array },
        modelValue:     { type: String },
        isForceUppercase:   {type:Boolean, default:true}
    },
    emits: ['update:modelValue', 'keyup'],
    beforeMount() {
        if (this.modelValue) { this.focused = 'focused is-focused' }
    },
    data: () => {
        return {
            focused: '',
        };
    }
}
</script>
<style scoped>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
</style>