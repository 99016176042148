<template>
    <!-- <div :class="'col-'+col_"> -->
        <label v-if="!detail_" :class="['mb-0 text-primary fw-bold',title_ ? '':'d-none']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <div :class="(detail_ ? '': '') + 'input-group'">
            <v-select
            :name="id_"
            :id="id_"
            :placeholder="placeholder_ ?? label "
            :disabled="disabled_"
            :readonly="isLoading"
            :class="classed_ + (detail_ ?' v-select-detail ': '') + (!error_?'':' invalid')"
            :options="filtered"
            :clearable="clearable_"
            @update:modelValue="confirmation"
            :modelValue="input"
            :label="label_"   
            :reduce="name => (valueToString_ ? (name[id_]).toString(): name[id_])"
            :autoscroll="false"
            @open="onOpen"
            @close="onClose"
            :filterBy="filterBy_"
            :title="required_?'Required':'Optional'"
            :searchable="searchable_"
            @search="query => searchData(query)">
                    <!-- <template #selected-option="value">
                        {{ value }}
                        {{ filtered }}
                    </template> -->
                    <!-- <template #no-options>
                        <div class="text-primary">
                            <div class="loader-dots mx-auto" v-if="isLoading"></div>
                        {{ (isLoading) ? 'Memuat...':(filtered.length == 0 && search.length == 0 ) ? 'Data Kosong' : 'Pencarian Tidak Ditemukan....' }}
                        </div>
                    </template> -->

                    <template #search="{attributes, events}">
                        <input class="vs__search" 
                        v-bind="attributes"
                        :readonly="isLoading"
                        style="text-transform: uppercase;"
                        v-on="events"/>
                        <!-- @input="$emit('input',$event.target.value.toUpperCase())" -->
                    </template>
<!--                     
                    <template #list-footer v-if="hasNextPage">
                        <li ref="load" class="text-center">
                            <div class="w-100 justify-content-center text-center">
                                <div class="loader-dots mx-auto"></div>
                                Memuat...
                            </div>
                        </li>
                    </template> -->

                    <template #no-options>
                        <div class="text-primary">
                            <div class="loader-dots mx-auto" v-if="isLoading && filtered.length === 0"></div>
                            {{ isLoading && filtered.length === 0 ? 'Memuat...' : (filtered.length === 0 && search.length === 0) ? 'Data Kosong' : 'Pencarian Tidak Ditemukan....' }}
                        </div>
                    </template>

                    <template #list-footer v-if="hasNextPage && !isLoading">
                        <li ref="load" class="text-center">
                            <div class="w-100 justify-content-center text-center">
                                <div class="loader-dots mx-auto"></div>
                                Memuat...
                            </div>
                        </li>
                    </template>



                    <template #spinner>
                        <div v-if="isLoading" style="height: 20px !important; width:20px!important;">
                            <span class="loader-small"></span>
                            <!-- <font-awesome-icon :icon="['fas', 'spinner']" spin/> -->
                        </div>
                    </template>
                    <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <font-awesome-icon :icon="['fas', 'caret-square-down']" style="font-size: 1rem;" class="text-primary" v-if="!isLoading"/>
                            </span>
                    </template>
                    <!-- <template #spinner="isLoading">
                        <div
                            v-if="isLoading"
                            style="border-left-color: rgba(88, 151, 251, 0.71)"
                            class="vs__spinner"
                        >
                        LOADING
                        </div>
                    </template> -->
            </v-select>    
        </div>
        <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
        </small>

        <div class="modal fade" tabindex="-1" :id="'selectModal-'+title_" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmation Change</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>{{ confirmMsg_ }}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" style="margin-left: 5px;" @click="resetData"> Cancel </button>
                        <button type="button" class="btn btn-warning" style="margin-left: 5px;" @click="updateData"> OK </button>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>

export default {
    name: "InfiniteScroll",
    props: {
        detail_:                    { type: Boolean, default: false},
        onChange_:                  { type: Function, required: true, },
        url_:                       { type: String, required: true, },
        value:                      { type: [ String, Number ], default: null, },
        valueToString_:             { type: Boolean, default: false, },
        id_:                        { type: String, default: 'Id' },
        label_:                     { type: String, default:'concatedLabel' },
        labelConcat_:               { type: String, default:null },
        labelConcatDeliminator_:    { type: String, default:" - " },
        labelWrapSymbol_:           { type: String, default: "" },
        title_:                     { type: String, default: null, },
        required_:                  { type: Boolean, default: false, },
        searchable_:                { type: Boolean, default: true },
        except_:                    { type: Array, required: false, default: () => [], },
        disabled_:                  { type: Boolean, default: false, },
        updated_:                   { type: Boolean, default: false, },
        clearable_:                 { type: Boolean, default: false, },
        classed_:                   { type: String, default: 'style-chooser w-100 bg-white rounded', },
        placeholder_:               { type: String, default: '', },
        params_:                    { type: Object, default: () => {}, },
        // col_:                    { type: String, default: '12' },
        index_:                     { type: [String, Number] },
        tranform_:                  { type: Function, default: (dt) => dt },
        confirmUpdate_:             { type: Boolean, default: false },
        confirmMsg_:                { type: String, default: '' },
        filterable_:                { type: Boolean, default: true },
        firstValue_:                { type: Boolean, default: false },
        error_:                     { type: Array, default: null },
        orderData_:                 { type:String, default:'ASC'},  
        unshiftData_:               { type: Object, default: null, },
    },
    // data: () => {
    //     return {
    data: () => {return{
            observer: null,
            limit: 10,
            search: '',
            initId:'',
            menu: [],
            input: 0,
            delayTimer: null,
            meta: {
                pageNumber: 1,
                pageSize: 99, 
                isThereNextPage: true,
                TotalPages: 1,
            },
            internalUrl: null,
            isLoading: false,
            submited: false,
            tempBool: true,
            unshiftData: null,
        };
    },
    async beforeMount() {
        this.unshiftData = this.unshiftData_;
        this.input = this.value;
        this.internalUrl = this.url_;
        
        if(this.updated_ || this.disabled_ ){
            // this.search = this.value ? this.value: '';
            this.initId = this.value ? this.value: '';
        }
        
        if((this.value || this.firstValue_)){
            // this.search = this.value ? this.value: ''
            this.initId = this.value ? this.value: '';
            await this.fetchData();
        }
        if(this.firstValue_ && !this.value && this.menu.length >= 1) { 
            this.input = (this.menu[0][this.id_]).toString();
            this.updateData();
        }
    },
    watch: {
        value: function (val) {
            // if(!val || val == 0) {
                this.input = val;
            // }
        },
    },
    mounted () {
        this.observer = new IntersectionObserver(this.infiniteScroll);
    },
    computed: {
        hasNextPage () {
            return this.meta.isThereNextPage;
        },
        filtered() {
            var data = this.menu.filter(item => !this.filteredExcept.includes(item[this.id_]));
            return data;
        },
        filteredExcept() {
            return this.except_.filter(item => item != this.input);
        },
    },
    methods: {
        // filterBy_(option, label, search) {
        filterBy_(){
            return this.filtered.length;
            // return (label || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
        },
        async onOpen () {
            this.tempBool = true
            if(this.menu.length < 2  || this.updated_ || this.value) {
                this.search = ''
                this.initId = '';
                await this.fetchData();
            }
            if (this.hasNextPage) {
                await this.$nextTick();
                this.observer.observe(this.$refs.load);
            }
        },
        onClose () {
            clearTimeout(this.delayTimer)
            this.delayTimer = null
            this.tempBool = false
            this.observer.disconnect();
        },
        async infiniteScroll ([{isIntersecting}]) {
            if (isIntersecting && this.hasNextPage) {
                await this.fetchData();
            }
        },
        async fetchData() {

            if (this.isLoading) return;
            this.isLoading = true;

            var param = {
                search: this.search,
                pageSize: this.meta.pageSize,
                pageNumber: this.meta.pageNumber,
                ...this.params_                
            };
            
            if (this.search && this.params_.search) {
                param.search = this.params_.search + ' ' + this.search;
            }

            if(this.initId) param.initId = this.initId;
            

            // if(this.updated_ || this.disabled_) {
            //     param.filterField = param.filterField + ',' + this.id_;
            // }

            try {
                // await new Promise(resolve => setTimeout(resolve, 1000));
                const response = await this.fetchData_(this.internalUrl, param);
                let {Data, PageSize, PageNumber, TotalPages} = response;
                
                this.isLoading = false;
                                
                if (PageNumber < TotalPages) this.meta.pageNumber += 1;
                this.meta.isThereNextPage = (PageNumber < TotalPages);
                this.meta.pageSize = PageSize;
                
                if(this.search == '' && this.unshiftData){
                    Data.unshift(this.unshiftData);
                }

                if((this.labelConcat_)){
                    const wrapSymbol = this.labelWrapSymbol_.split("");
                    
                    Data = Data.map(item => ({
                        ...item,
                        concatedLabel: this.labelConcat_.split(',').map(key => wrapSymbol.length == 2 ? `${wrapSymbol[0]}  ${item[key]}  ${wrapSymbol[1]}` : item[key]).join(this.labelConcatDeliminator_)
                    }));
                }
                this.menu = this.arrayUnique( this.menu.concat(Data) ).map(item => { return this.tranform_(item) }).filter(obj => obj );
            } catch (error) {
                console.log(error)
            }
            this.isLoading = false;
        },
        confirmation(e) {
            this.input = e;
            if(this.confirmUpdate_) { document.getElementById('selectModal-'+this.title_).click(); } 
            else { this.updateData(); }
        },
        updateData() {
            // document.getElementById('selectModal-'+this.title_).hide('hide');
            // document.getElementById('selectModal-'+this.title_).click();
            const value = (this.valueToString_ ? parseInt(this.input): this.input)
            var data = this.menu.find(item => item[this.id_] === value);
            if (typeof this.index_ == 'number') this.onChange_(data, this.index_)
            else this.onChange_(data)
        },
        resetData() {
            this.input = this.value;
        },
        searchData(value) {
            if(this.filterable_) {
                if(this.delayTimer) {
                    clearTimeout(this.delayTimer);
                    this.delayTimer = null
                }
                this.delayTimer = setTimeout(() => {
                    if(this.tempBool){
                        this.meta = {
                            pageNumber: 1,
                            pageSize: this.params_.pageSize ?? 10, 
                            isThereNextPage: false,
                            TotalPages:1,
                        },
                        this.menu = [];
                        this.search = value.toUpperCase();
                        this.fetchData();
                    }
                }, 1000);
            }
        },
        arrayUnique(array) {
        var a = array.concat();
        for(var i=0; i<a.length; ++i) {
            for(var j=i+1; j<a.length; ++j) {
                if(a[i][this.id_] === a[j][this.id_])
                    a.splice(j--, 1);
                }
            }
            return a;
        },
    }
}
</script>

<style>
    .loader {
        text-align: center;
        color: #bbbbbb;
    }
    .valid {
        border: solid #28a745 1px !important;
        border-radius: 0.375rem !important;
    }
    .invalid {
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
    .select-invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }
    .select-valid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #28a745;
    }
    .style-chooser .vs__search::placeholder{
        border: 0px solid #d2d6da !important;
    }

    .style-chooser .vs__dropdown-toggle{
        border: 1px solid #d2d6da;
        border-radius: 0.375rem !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        line-height: 1rem !important; 
        transition: 0.35 ease !important;
    }

    .style-chooser .vs__dropdown-menu {
        position: absolute !important;
        margin-top: 4px !important;
        max-height: 160px !important;
    }

    td .style-chooser .vs__dropdown-menu {
        margin-top: 4px !important;
        max-height: 160px !important;
        position: static !important;
        z-index: 9999 !important;  
    }
    
    /* .vs__dropdown-menu { */
        /* position: static !important; */
    /* } */

    .vs__actions{
        padding: 0rem !important;
    }
    .vs__clear{
        padding-bottom: 0.25rem !important;
    }
    /* .dropdown-menu {    
        z-index:10030 !important;
    } */
    
    .vs__dropdown-toggle{
        padding: 0.1rem 0.4rem !important;
        margin: 0px !important;
    }

    .style-chooser .vs__search{
        margin: 0px !important;
        padding: 0px !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        line-height: 1rem !important; 
        transition: 0.2 ease !important;
    }
    .vs__dropdown-option{
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .style-chooser .vs__selected{
        margin:0px !important;
        
    }

    .vs--multiple .vs__selected-options .vs__selected{
        background-color: red;
        margin-right: 0.2rem !important;
        margin-bottom: 0.2rem !important;
    }

    .vs--disabled .vs__dropdown-toggle,
    .vs--disabled .vs__search,
    .vs--disabled .vs__actions,
    .vs--disabled .vs__clear,
    .vs--disabled .vs__open-indicator{
        background-color: #dcdde2 !important;
    }

    .style-chooser .vs__clear,
    .style-chooser .vs__open-indicator {
    fill: #394066 !important;
    }
</style>