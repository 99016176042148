<template>
    <label :class="['mb-0 text-primary fw-bold',title_ == '' ? 'd-none':'']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <div :class="'input-group ' + classGroup_ + ' ' + focused + ' ' + (error_ ? 'is-invalid': '')">
            <input
                ref="inputRef" 
                type="text"
                :value="modelValue" 
                :class="'form-control bg-white ' + class_" 
                :placeholder="placeholder_" 
                :readonly="readonly_" 
                autocomplete="off" 
                @focus="focused = 'focused is-focused'" 
                v-on:blur="modelValue ? '': (focused = '')"
                @keyup="$emit('keyup', $event)" :disabled="readonly_">
        </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
    </small>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'CurrencyInput',
    props: {
        onChange:       { type: Function, required: false },
        modelValue:     Number, // Vue 2: value
        curr:           {type: String, default:'IDR'},
        decimal:        {type: Number, default:0},
        // options:        {Object,default:{currency : "IDR"}},
        classGroup_:    { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:         { type: String, default: '' },
        title_:         { type: String, default: '' },
        placeholder_:   { type: String },
        readonly_:      { type: Boolean, default: false },
        error_:         { type: Array },
        range:          { type: Object, default:null},
        currencyDisplay:{ type: String,default:"hidden" },
        useGrouping:    { type: Boolean, default: false },
        required_:      {type: Boolean, default: false}
    },
    setup(props) {
        const option ={
            "currency"    : props.curr,
            "valueRange"  : props.range,
            "precision"   : props.decimal,
            "currencyDisplay" : props.currencyDisplay,
            "useGrouping": props.useGrouping
        }
        const { inputRef } =  useCurrencyInput(option) 

        return { inputRef }
    },
    emits: ['update:modelValue', 'keyup'],
  
    data: () => {
        return {
            focused: '',
        };
    },
    beforeMount() {
        if (this.modelValue) { this.focused = 'focused is-focused' }
    },
}
</script>
<style scopedz>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
</style>