<template>
    <!-- <div :class="'col-'+col_"> -->
        <label v-if="!detail_" :class="['mb-0 text-primary fw-bold',title_ ? '':'d-none']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <div :class="(detail_ ? '': '') + 'input-group'">
            <v-select
            :name="id_"
            :id="id_"
            :placeholder="placeholder_ ?? label_ "
            :disabled="disabled_"
            :class="classed_ + (detail_ ?' v-select-detail ': '') + (!error_?'':' invalid')"
            :options="filtered"
            :clearable="clearable_"
            @update:modelValue="confirmation"
            :modelValue="input"
            :label="label_"
            :reduce="name => (valueToString_ ? (name[id_]).toString(): name[id_])"
            :autoscroll="false"
            @open="onOpen"
            @close="onClose"
            :title="required_?'Required':'Optional'"
            :searchable="searchable_">
                    <template #no-options>
                        <div class="text-primary">Pencarian Tidak Ditemukan....</div>
                    </template>
                    <template #search="{attributes, events}">
                        <input class="vs__search" 
                        v-bind="attributes"
                        v-on="events"/>
                    </template>
                    <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <font-awesome-icon :icon="['fas', 'caret-square-down']" style="font-size: 1rem;" class="text-primary"/>
                            </span>
                    </template>
            </v-select>    
        </div>
        <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
            <span v-for="(error,index) in error_" :key="index" class="d-block">
                {{ error }}
            </span>
        </small>

        <div class="modal fade" tabindex="-1" :id="'selectModal-'+title_" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmation Change</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>{{ confirmMsg_ }}</p>
                    </div>
                    <div class="modal-footer">
                        
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" style="margin-left: 5px;" @click="resetData"> Cancel </button>
                        <button type="button" class="btn btn-warning" style="margin-left: 5px;" @click="updateData"> OK </button>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
     
</template>

<script>
// import { Modal } from 'bootstrap';

export default {
    name: "InfiniteScroll",
    props: {
        data_:           { type: Array, default: () => []},
        detail_:         { type: Boolean, default: false},
        onChange_:       { type: Function, required: true, },
        value:           { type: [ String, Number, Boolean ], default: null, },
        valueToString_:  { type: Boolean, default: false, },
        id_:             { type: String, default: 'Id' },
        label_:                     { type: String, default:'concatedLabel' },
        labelConcat_:               { type: String, default:null },
        labelConcatDeliminator_:    { type: String, default:" - " },
        title_:          { type: String, default: null, },
        required_:       { type: Boolean, default: false, },
        searchable_:     { type: Boolean, default: true },
        except_:         { type: Array, required: false, default: () => [], },
        disabled_:       { type: Boolean, default: false, },
        clearable_:      { type: Boolean, default: false, },
        classed_:        { type: String, default: 'style-chooser w-100 bg-white rounded', },
        placeholder_:    { type: String, default: '', },
        index_:          { type: [String, Number] },
        confirmUpdate_:  { type: Boolean, default: false },
        confirmMsg_:     { type: String, default: '' },
        filterable_:     { type: Boolean, default: true },
        firstValue_:     { type: Boolean, default: false },
        error_:          { type: Array, default: null },
    },
    data: () => ({
        observer: null,
        limit: 10,
        search: '',
        menu: [],
        input: 0,
        submited: false,
        tempBool: true,
        modalConfirmUpdate: null,
    }),
    async beforeMount() {
        this.input = this.value;
        if(this.disabled_ ) this.search = this.value? this.value: '';
        if(this.firstValue_ && !this.value && this.menu.length >= 1) {
            this.input = (this.menu[0][this.id_]).toString();
            this.updateData();
        }
    
    },
    watch: {
        value: function (val) {
            // if(!val || val == 0) {
                this.input = val;
            // }
        },
    },
    mounted () {
        this.observer = new IntersectionObserver(this.infiniteScroll); 
        // this.modalConfirmUpdate = new Modal("#selectModal-"+title_);
        this.menu = this.data_;
        if((this.labelConcat_)){
            this.menu = this.data_.map(item => ({
                ...item,
                concatedLabel: this.labelConcat_.split(',').map(key => item[key]).join(this.labelConcatDeliminator_)
            }));
        }
    },
    computed: {
        filtered() {
            var data = this.menu.filter(item => !this.filteredExcept.includes(item[this.id_]));
            if((this.labelConcat_)){
                data = data.map(item => ({
                    ...item,
                    concatedLabel: this.labelConcat_.split(',').map(key => item[key]).join(this.labelConcatDeliminator_)
                }));
            }
            return data;
        },
        filteredExcept() {
            return this.except_.filter(item => item != this.input);
        },
    },
    methods: {
        async onOpen () {
            this.tempBool = true
            if(this.menu.length < 2  ) {
                this.search =''
                
            }
        },
        onClose () {
            this.tempBool = false
            this.observer.disconnect();
        },
        async infiniteScroll ([{isIntersecting}]) {
            if (isIntersecting) {
                console.log(isIntersecting)
            }
        },
        confirmation(e) {
            this.input = e
            if(this.confirmUpdate_) { 
                document.getElementById('selectModal-'+this.title_).click();
            } else { 
                this.updateData(); 
            }
        },
        updateData() {
            // document.getElementById('selectModal-'+this.title_).hide('hide');
            document.getElementById('selectModal-'+this.title_).click();
            const value = (this.valueToString_ ? parseInt(this.input): this.input)
            var data = this.menu.find(item => item[this.id_] === value);
            if (typeof this.index_ == 'number') this.onChange_(data, this.index_)
            else this.onChange_(data)
        },
        resetData() {
            this.input = this.value;
        },
    }
}
</script>

<style>
    .loader {
        text-align: center;
        color: #bbbbbb;
    }
    .valid {
        border: solid #28a745 1px !important;
        border-radius: 0.375rem !important;
    }
    .invalid {
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
    .select-invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
    }
    .select-valid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #28a745;
    }
    .style-chooser .vs__search::placeholder{
        border: 0px solid #d2d6da !important;
    }
    
    .style-chooser .vs__dropdown-toggle,
    .style-chooser .vs__dropdown-menu {
        /* background: none; */
        border: 1px solid #d2d6da;
        border-radius: 0.375rem !important;
        padding: 0.4rem 0.5rem !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important; 
        transition: 0.2 ease !important;
    }
    .vs__dropdown-menu{
        position: static !important;
    }
    .style-chooser .vs__search{
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important; 
        transition: 0.2 ease !important;
    }
    
    .style-chooser .vs__dropdown-menu {
        position: absolute !important;
        margin-top: 4px !important;
        max-height: 160px !important;
    }

    td .style-chooser .vs__dropdown-menu {
        position: static !important;
        z-index: 9999 !important;  
        margin-top: 4px !important;
        max-height: 160px !important;
    }

    .style-chooser .vs__selected{
        margin:0px !important;
    }

    .style-chooser .vs__clear,
    .style-chooser .vs__open-indicator {
    fill: #394066 !important;
    }
</style>