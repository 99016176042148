<template>
    <div :class="'input-group ' + (detail_? '': 'my-3') + ' ' + classGroup_ + ' ' + focused + ' ' + (error_ ? 'is-invalid': '')">
        <label class="form-label" v-if="title_">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <input :type="type_" 
            :value="modelValue" 
            :class="'form-control ' + class_ + ' ' + (detail_? 'border-0': '')" 
            :placeholder="placeholder_" 
            :readonly="true"
            :style="(detail_? 'padding: 0px !important':'')" 
            autocomplete="off" >
        <div class="input-group-prepend" style="padding: 0px !important; height: 100%">
            <button class="btn btn-success btn-sm mb-0" style="height: 100%;" @click="openModal" data-bs-toggle="modal" :data-bs-target="'#exampleModal-'+title_"><font-awesome-icon :icon="['fas', 'book-open']" size="lg" /></button>
            <button class="btn btn-danger btn-sm mb-0" style="height: 100%;" @click="clear"><font-awesome-icon :icon="['fas', 'times']" size="lg" /></button>
        </div>
    </div>
    <small v-if="error_" class="text-danger fst-italic">{{ error_ }}</small>

    <div class="modal fade" tabindex="-1" :id="'exampleModal-'+title_" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title_ }}</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <font-awesome-icon :icon="['fas', 'times-circle']" />
                    </button>
                </div>
                <div class="modal-body">
                    <data-table :initialFields_="initialFields" :api_="api_" ref="datatables" :localStorage_="false">
                        <template v-if="multiple_" #action-header>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :checked="checkAllStatus" @change="checkAll($event.target.checked)">
                            </div>
                        </template>
                        <template #action="{item}">
                            <div v-if="multiple_" class="form-check text-center">
                                <input class="form-check-input" type="checkbox" :checked="checkData(item)" @change="checkItem($event.target.checked, item)">
                            </div>
                            <button v-else title="Choose" type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-success btn-sm" @click="selected(item)" style="margin-right: 2px; padding-top: 0px; padding-bottom: 0px;">
                                <font-awesome-icon :icon="['fas', 'eject']" />
                            </button>
                        </template>
                    </data-table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Close</button>
                    <button v-if="multiple_" type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" @click="onClick_(dataMultiple)">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        classGroup_:    { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:         { type: String, default: '' },
        title_:         { type: String },
        type_:          { type: String, default: 'text' },
        placeholder_:   { type: String },
        readonly_:      { type: Boolean, default: false },
        required_:      { type: Boolean, default: false },
        error_:         { type: String },
        initialFields_: { type: Array, required: true },
        detail_:        { type: Boolean, default: false },
        api_:           { type: Object, required: true },
        multiple_:      { type: Boolean, default: false },
        onClick_:       { type: Function, default() {} },
        id_:            { type: String, default: 'id' },
        modelValue:     { type: String }
    },
    emits: ['update:modelValue'],
    beforeMount() {
        this.initialFields = [ { label: 'Action', position: 'text-center' }, ...this.initialFields_ ];
    },
    methods: {
        changePageTitle(title) {
            this.$emit('update:modelValue', title)
        },
        openModal() {
            document.getElementById('exampleModal-'+this.title_).click();
        },
        selected(item) {
            this.onClick_(item)
        },
        clear() {
            this.onClick_({})
            document.getElementById('exampleModal-'+this.title_).click();
        },
        checkAll(check) {
            var items = [ ...this.$refs.datatables.dataFields ];
            items.forEach( item => item.selected = check);
            this.dataMultiple = check ? items: [];
            this.checkAllStatus = check;
            
            this.$refs.datatables.items = items;
        },
        checkItem(check, item) {
            if(check) {
                item.selected = check
                this.dataMultiple.push(item)
            } else {
                item.selected = check
                this.dataMultiple = this.dataMultiple.filter(value => (value[this.id_] != item[this.id_]))
            }
        },
        checkData(item) {
            let checked = item.selected;
            let find = this.dataMultiple.find(element => (element[this.id_] == item[this.id_]) );
            if (find) {
                checked = true;
            } else {
                if (item.selected) {
                    this.dataMultiple.push(item)
                }
            }
            return checked;
        },
    },
    data: () => {
        return {
            focused: '',
            isOpen: false,
            initialFields: [],
            dataMultiple: [],
            checkAllStatus: false
        };
    },
}
</script>

<style scoped>
    label {
        font-size: 11px;
        top: -20% !important;
        color: #e91e63;
    }
</style>