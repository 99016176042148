<!-- <template>
    <div :class="'input-group' + classGroup_">
        <label :class="['mb-0 text-primary fw-bold', title_ == '' ? 'd-none' : '']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <label for="formFile" class="form-label">Upload File</label>
        <input type="file"
            accept=".xlsx, .xls"
            :class="'form-control ' + class_ + focused + (error_ ? 'is-invalid' : '')"
            :readonly="readonly_"
            @focus="focused = (!readonly_ ? ' focused is-focused' : '')"
            @blur="focused = ''"
            :multiple='false'
            @change="handleFileUpload"/>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
        <span v-for="(error, index) in error_" :key="index" class="d-block">
            {{ error }}
        </span>
    </small>
</template> -->

<template>
    <div :class="'input-group' + classGroup_">
        <label :class="['mb-0 text-primary fw-bold', title_ == '' ? 'd-none' : '']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
        <input type="file"
            id="fileInput"
            accept=".xlsx, .xls"
            :class="'form-control ' + class_ + focused + (error_ ? 'is-invalid' : '')"
            :readonly="readonly_"
            @focus="focused = (!readonly_ ? ' focused is-focused' : '')"
            @blur="focused = ''"
            :multiple='false'
            @change="handleFileUpload"/>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
        <span v-for="(error, index) in error_" :key="index" class="d-block">
            {{ error }}
        </span>
    </small>
</template>


<script>
export default {
    props: {
        classGroup_: { type: String, default: '' },
        class_: { type: String, default: '' },
        title_: { type: String, default: '' },
        type_: { type: String, default: 'file' },
        placeholder_: { type: String },
        readonly_: { type: Boolean, default: false },
        required_: { type: Boolean, default: false },
        error_: { type: Array },
        modelValue: { type: String },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            focused: '',
            inputType: '',
        };
    },
    methods: {
        handleFileUpload(event) {
            this.$emit('update:modelValue', event);
        },
    },
};
</script>
<style>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    } 

    input::file-selector-button {
        font-weight: bold !important;
        color: white !important;
        background-color: #054683 !important;
        
        padding-left: 5px !important;
        padding-right: 5px !important;
        
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;

        margin-left: 0rem !important;
        margin-right: 10px !important;
        border: thin solid grey !important;
        border-radius: 3px !important;
    }
    

    .form-control[type=file]{
        padding: 0.5rem 0rem !important;
    }
</style>