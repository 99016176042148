<template>
<div class="d-flex flex-row pb-2 pt-1 short-content-hover rounded">
    <div class="d-flex flex-fill">
        <div class="align-self-center me-2 ms-1" style="height: 35px !important; min-width: 35px !important; max-width: 35px !important; text-align: center; object-fit: contain;">
            <slot name="sideImage"></slot>
        </div>

        <div class="align-self-center">
            <div class="d-flex align-items-center">
                <div class="m-0 text-primary fw-bolder lh-sm text-truncate-multiline-one" style="font-size: 0.8rem !important;">
                    {{ title_.toUpperCase() }}
                </div>
                
            </div>

            <div class="text-900 text-black text-nowrap text-dark-blue lh-sm m-0">
                <NumberAnimation :from="0" :to="amount_" :format="formatNumberToCurrency" :duration="1" autoplay easing="easeOutSine"/>
            </div>
        </div>

        <div class="ms-1 align-self-center me-2">
            <span class="badge text-bg-success text-white px-1 py-1" style="font-size: 0.8rem !important;" v-if="percentage_ != ''">
            <NumberAnimation :from="0" :to="percentage_" :format="formatNumberToPercent" :duration="1" autoplay easing="easeOutSine"/>
            </span>
        </div>
    </div>

    <div class="d-flex flex-column m-md-0 me-2 justify-content-center pe-md-2 text-center" v-if="firstBadgeContent_ != null || secondBadgeContent_ != null">
        <div>
            <span class="badge bg-primary text-nowrap" v-if="firstBadgeContent_" v-tippy='firstBadgeContent_.title'>
                <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="me-1"/>
                <NumberAnimation :from="0" :to="firstBadgeContent_.value" :format="formatNumber" :duration="1" autoplay easing="easeOutSine"/>
                <span class="ms-1 d-none d-sm-inline">{{ firstBadgeContent_.title }}</span>
            </span>
        </div>

        <div>
            <span class="badge bg-danger text-nowrap" v-if="secondBadgeContent_" v-tippy='secondBadgeContent_.title'>
                <font-awesome-icon :icon="['fas', 'shopping-bag']" class="me-1"/> 
                <NumberAnimation :from="0" :to="secondBadgeContent_.value" :format="formatNumber" :duration="1" autoplay easing="easeOutSine"/>
                <span class="ms-1 d-none d-sm-inline">{{ secondBadgeContent_.title }}</span>
            </span>
        </div>
    </div>

</div>


<!-- <div class="d-flex flex-wrap short-content-hover py-2">
    <div style="height: 40px !important; width:40px !important; text-align: center; object-fit: contain;" class="align-self-center mx-2">
        <slot name="sideImage"></slot>
    </div>

    <div class="flex-fill align-self-center">
        <div class="d-flex align-items-center">
            <div class="m-0 text-primary fw-bolder text-nowrap lh-sm" style="font-size: 0.9rem !important;">{{ title_.toUpperCase() }}</div>
            <div class="ms-1">
                <span class="badge text-bg-success text-white px-2 py-1" style="font-size: 0.8rem !important;" v-if="percentage_ != ''">
                    <NumberAnimation :from="0" :to="percentage_" :format="formatNumberToPercent" :duration="1" autoplay easing="easeOutSine"/>
                </span>
            </div>    
        </div>

        <h6 class="text-900 text-black text-nowrap text-dark-blue lh-sm m-0">
            <NumberAnimation :from="0" :to="amount_" :format="formatNumberToCurrency" :duration="1" autoplay easing="easeOutSine"/>
        </h6>
    </div>
    
    <div class="d-flex flex-md-column flex-row mt-2 m-md-0 gap-1 me-2 justify-content-center pe-md-2 text-center" v-if="firstBadgeContent_ != null || secondBadgeContent_ != null">
        <div>
            <span class="badge bg-primary text-nowrap" v-if="firstBadgeContent_">
                    <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="me-1"/>
                    <NumberAnimation :from="0" :to="firstBadgeContent_.value" :format="formatNumber" :duration="1" autoplay easing="easeOutSine"/>
                    {{ firstBadgeContent_.title }}
            </span>
        </div>
        <div>
            <span class="badge bg-danger text-nowrap" v-if="secondBadgeContent_">
                <font-awesome-icon :icon="['fas', 'shopping-bag']" class="me-1"/> 
                <NumberAnimation :from="0" :to="secondBadgeContent_.value" :format="formatNumber" :duration="1" autoplay easing="easeOutSine"/>
                {{ secondBadgeContent_.title }}
            </span>
        </div>
    </div>
</div> -->

</template>

<script>
import NumberAnimation from "vue-number-animation";

export default {
    props:{
        title_: {type:String, default:''},
        amount_: {type:Number, default: 0},
        percentage_: {type:String, default: ''},
        firstBadgeContent_:{type:Object, default: () => (null)},
        secondBadgeContent_:{type:Object, default: () => (null)},
    },
    components:{
        NumberAnimation
    }
}
</script>

<style>

</style>