<template>
<a :class="['d-inline-block border border-1 rounded text-center text-white lh-sm',(isCheckAllitem) ? 'bg-primary':'bg-white border-dark']" href="javascript:void(0)" style="width: 25px !important;height: 25px !important;">
    <font-awesome-icon :icon="['fas', 'check']" class="align-middle"/>
</a>
</template>

<script>
export default {
    name: 'CheckBoxInputCustom',
    props: {
        title_:         { type: String, default:'' },
        disabled_:      { type: Boolean, default: false },
        error_:         { type: Array },
    }, 
}
</script>

<style>

</style>