import { createApp } from 'vue'
import App from './App.vue'
import mixin from './mixin.js'
import store from './store'
import { router } from './router';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment-timezone';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { Money3Directive } from "v-money3";

import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css' // optional for styling

import "./assets/styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/css/material-dashboard.css';
// import './assets/js/material-dashboard.js';

import SelectOptionCustom from "@/components/input/SelectOptionCustom.vue"
import InputNumberCustom from '@/components/input/InputNumberCustom.vue';
import CurrencyInputCustom from '@/components/input/CurrencyInput.vue';
import InputFromPopupCustom from '@/components/input/InputFromPopupCustom.vue';
import InputTextCustom from '@/components/input/InputTextCustom.vue';
import InputDateCustom from '@/components/input/InputDateCustom.vue';
import InputTextAreaCustom from '@/components/input/InputTextAreaCustom.vue';
import InputFileCustom from '@/components/input/InputFileCustom.vue';
import SelectOptionLocalCustom from '@/components/input/SelectOptionLocalCustom.vue';
import InputDateRangeCustom from '@/components/input/InputDateRangeCustom.vue';
import CheckBoxInputCustom from '@/components/input/CheckBoxInputCustom.vue';

import BottomNavbar from '@/components/container/BottomNavbar.vue';
import SideBar from '@/components/container/SideBar.vue';
import NonSalesTopNavbar from '@/components/container/nonSalesTopNavbar.vue';
import ShortContentContainer from '@/components/container/ShortContentContainer.vue';

import PopupCustom from '@/components/PopupCustom.vue';
import DataTable from "@/components/DataTable.vue"
import DetailTable from "@/components/DetailTable.vue"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab);
library.add(far);
library.add(fas);

const app = createApp(App);
app.directive('money3', Money3Directive)
app.use(VueTippy,{directive: 'tippy'})
app.component('data-table', DataTable);
app.component("v-select", vSelect);
app.component('date-picker', Datepicker);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('input-number-custom', InputNumberCustom);
app.component('currency-input-custom', CurrencyInputCustom);
app.component('input-text-custom', InputTextCustom);
app.component('input-date-custom', InputDateCustom);
app.component('input-from-popup-custom',InputFromPopupCustom);
app.component('popup-custom', PopupCustom);
app.component('select-option-custom', SelectOptionCustom);
app.component('input-text-area-custom',InputTextAreaCustom);
app.component('bottom-navbar',BottomNavbar);
app.component('side-bar',SideBar);
app.component('non-sales-top-navbar', NonSalesTopNavbar);
app.component('short-content-container',ShortContentContainer);
app.component('select-option-local-custom',SelectOptionLocalCustom);
app.component('input-date-range-custom', InputDateRangeCustom);
app.component('detail-table',DetailTable);
app.component('input-file-custom',InputFileCustom);
app.component('Check-box-input-custom',CheckBoxInputCustom);

app.use(VueSweetalert2);
app.use(VueLoading);
app.use(store);
app.use(router);
app.use(moment);
app.mixin(mixin);
app.mount('#app');

global.vm = app;
