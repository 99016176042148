<template>
    <!-- Logout Modal -->
    <div class="modal fade" id="modalLogout" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 bg-primary">
                    <h1 class="modal-title fs-5 m-0 text-white">Logout</h1>
                    <button type="button" class="btn btn-danger m-0 px-2 py-1" data-bs-dismiss="modal"><font-awesome-icon :icon="['fas', 'times-circle']" style="font-size: 0.8rem !important;" /></button>
            </div>
        
            <div class="modal-body">
                Apakah Anda Yakin Ingin Keluar ??
            </div>

            <div class="modal-footer m-0">
                <button type="button" class="btn btn-secondary m-0 me-2" data-bs-dismiss="modal">Tidak, Batal</button>
                <button type="button" class="btn btn-outline-danger m-0" @click="meLogout">Iya, Logout</button>
            </div>
        </div>
    </div>
    </div>
    <!-- End Of Logout Modal -->

    <!-- Change Password Modal -->
    <div class="modal fade" id="modalChangePassword" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header px-3 py-2 bg-primary">
                        <h1 class="modal-title fs-5 m-0 text-white">Ganti Password</h1>
                        <button type="button" class="btn btn-danger m-0 px-2 py-1" data-bs-dismiss="modal"><font-awesome-icon :icon="['fas', 'times-circle']" style="font-size: 0.8rem !important;" /></button>
                </div>
            
                <div class="modal-body">
                    <input-text-custom :required_="true" title_="Password Lama" v-model="fieldsCP.OldPassword" :error_="errorsCP.OldPassword" type_="password" :isObsecureInput="true"></input-text-custom>
                    <input-text-custom :required_="true" title_="Password Baru" v-model="fieldsCP.NewPassword" :error_="errorsCP.NewPassword" type_="password" :isObsecureInput="true"></input-text-custom>
                    <input-text-custom :required_="true" title_="Konfirmasi Password Baru" v-model="fieldsCP.ConfirmPassword" :error_="errorsCP.ConfirmPassword" type_="password" :isObsecureInput="true"></input-text-custom>
                </div>

                <div class="modal-footer m-0">
                    <button type="button" class="btn btn-secondary m-0 me-2" data-bs-dismiss="modal">Tidak, Batal</button>
                    <button type="button" class="btn btn-primary m-0" @click="meChangePassword">Simpan</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Change Password Modal -->

    <!-- Exhibition Modal -->
    <div class="modal fade" id="modalNavbarFilter" tabindex="-1" data-bs-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header px-3 py-2 bg-primary">
                <h1 class="modal-title fs-5 m-0 text-white">Filter Exhibition</h1>
                <button type="button" class="btn btn-danger m-0 px-2 py-1" data-bs-dismiss="modal" :disabled="isRefresh"><font-awesome-icon :icon="['fas', 'times-circle']" style="font-size: 0.8rem !important;" /></button>
            </div>
        
        <div class="modal-body">
            <div class="mt-2">
                <!-- apiCampaign -->
                <select-option-custom
                    :onChange_="SetCampaign"
                    :url_="apiCampaign.model"
                    :value="fields.CampaignActiveId"
                    :params_="apiCampaign.parameter"
                    label_="CampaignName"
                    title_="Campaign"
                    :required_="true"
                    :error_="errors.CampaignActiveId"
                    :disabled_="isRefresh"/>
            </div>
            
            <div class="mt-2">
                <select-option-custom
                    :key="'exhibition-'+fields.CampaignActiveId"
                    :params_="apiExhib.parameter"
                    :onChange_="SetExhibition"
                    :url_="apiExhib.model"
                    :value="fields.ExhibitionActiveId"
                    label_="ExhibitionName"
                    :disabled_="isRefresh || fields.CampaignActiveId == 1"
                    title_="Exhibition"
                    :error_="errors.ExhibitionActiveId"
                    :required_="true"/>
            </div>
            <!-- {{ currentRoutePath.fullPath }} -->
            <div class="mt-2" v-if="!usertype.includes('SALES_COUNTER') && currentRoutePath.fullPath == '/dashboard'">
                <input-date-range-custom 
                    title_="Periode (Dashboard)"
                    :required_="true"
                    placeholder_="dd/mm/yyyy - dd/mm/yyyy" 
                    :clearable_="true"
                    v-model="FilterPeriode"
                    :minDate_="minDate"
                    :maxDate_="maxDate"
                    :onChange_="SetPeriode"
                ></input-date-range-custom>
            </div>

            
        </div>
        <div class="modal-footer m-0">
            <div class="row">
                <div class="col-12 text-center" v-if="isRefresh">
                        <div class="loader-dots mx-auto"></div>
                        <span class="text-secondary fw-medium">Tunggu Bentar, Sedang Menyimpan Perubahan....</span>
                </div>
                <div class="col-12 text-center" v-else>
                    <button type="button" class="btn btn-danger m-0 me-2" data-bs-dismiss="modal"  :disabled="isRefresh">Batal</button>
                    <button type="button" class="btn btn-primary m-0" @click="ChangeFilterExhibition" :disabled="isRefresh">Simpan</button>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    <!-- End Of Exhibition Modal -->
    
    <nav class="navbar navbar-main navbar-expand-lgm py-2 px-2 gradient-border rounded shadow bg-white sticky-top" id="navbarBlur" data-scroll="true" style="z-index: 99999 !important;">
        <div class="d-flex justify-content-between w-100 align-items-center">
            <div class="me-1 user-select-none" v-tippy="'Menu Samping'">
                <div id="sidebarToggelButton" class="sidebarToggelButton rounded px-3 py-1 bg-primary text-white"  @click="$store.commit('toggleSidebar', !$store.state.sidebarVisible)">
                    <font-awesome-icon :icon="['fas', ($store.state.sidebarVisible) ? 'angle-double-left' : 'angle-double-right']" />
                </div>
            </div>
            
            <!-- <div v-tippy="'Filter Exhibition'" v-if="usertype.includes('ADMIN','PM','DIR_MARKETING','VICE_CEO','SPV_FIELD','SPV_BAM','AM')" class="bg-dark-blue px-3 py-1 m-0 rounded text-white" @click="showModalNavbarFilter()"><font-awesome-icon :icon="['fas', 'sliders-h']"/></div> -->
            <div v-tippy="'Filter Exhibition'" class="bg-primary px-3 py-1 m-0 rounded text-white" @click="showModalNavbarFilter()"><font-awesome-icon :icon="['fas', 'sliders-h']"/></div>
            <!-- v-if="!usertype.includes('SALES_COUNTER')" -->
            <div v-tippy="exhibitionShowCurrent" class="flex-fill text-center text-truncate mx-2 text-primary text-900 m-0 tracking-in-expand">
                {{ exhibitionShowCurrent }}
            </div>

            <div class="dropdown dropdown-center">
                <ul class="nav-item justify-content-end m-0 p-0" type="button" data-bs-toggle="dropdown"> 
                    <div class="d-md-flex flex-row align-items-center">
                        <div class="align-self-center text-center lh-1">
                            <font-awesome-icon :icon="['fas', 'user-circle']" style="font-size: 2rem !important;"/>
                        </div>
                        <div class="align-self-center d-none d-md-inline mx-2" style="font-size: 0.8rem;">
                            <div class="d-flex flex-column lh-sm text-nowrap" style="">
                                <div class="fw-bolder text-primary">{{ CompanyCode }} {{ usertype }}</div>
                                <div class="p-0 m-0">{{ username }}</div>
                            </div>
                        </div>
                        <font-awesome-icon :icon="['fas', 'caret-square-down']" class="d-none d-md-inline text-primary fs-5" />
                    </div>
                    <ul class="dropdown-menu dropdown-menu-end py-2'">
                        <li>
                            <a class="dropdown-item py-2 text-success fw-bolder" @click="openLinkWIKI()">
                                <font-awesome-icon :icon="['fas', 'atlas']" class="fa-fw"/> GOES WIKI
                            </a>
                        </li>

                        <li>
                            <a class="dropdown-item py-2" @click="showModalChangePassword()">
                                <font-awesome-icon :icon="['fas', 'key']" class="fa-fw"/> Ganti Password
                            </a>
                        </li>
                        
                        <li>
                            <a class="dropdown-item py-2" @click="showModalLogout()">
                                <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="fa-fw"/> Logout
                            </a>
                        </li>
                        
                    </ul>
                </ul>

            </div>
        </div>
    </nav>
</template>

<script>
import { Modal } from 'bootstrap';
import moment from 'moment-timezone';

export default {
    data() {
        return {
            modalLogout : null,
            modalChangePassword : null,
            modalNavbarFilter :null,
            username : '',
            usertype : '',
            exhibitionShowCurrent : '', 
            CompanyCode:'',
            isRefresh : false,
            fields : {
                ExhibitionActiveId : 0,
                ExhibitionActiveName : null,
                ExhibitionStartDate : null,
                ExhibitionEndDate : null,
                CampaignActiveId : 0,
                CampaignActiveName : null
            },
            fieldsCP:{
                OldPassword: null,
                NewPassword: null,
                ConfirmPassword:null,
            },
            errorsCP:{},
            errors : {},
            StartDate: "",
            EndDate: "",
            FilterPeriode: null,
            minDate: null,
            maxDate: null,
            apiExhib: {
                model: 'exhibition', 
                parameter: {
                    pageSize: 99,
                    noAll: false 
                }
            },
            apiCampaign: {
                model: 'campaign',
                parameter: {
                    pageSize: 99,
                    isWithRole: true
                }
            },
        }
    },
    methods: {
        openLinkWIKI() {
            window.open('https://wikigoes.olympicfurniture.id:85/expos', '_blank');
        },
        toggleSidebar(){
            this.$store.state.sidebarVisible = !this.$store.state.sidebarVisible;
        },
        SetPeriode(e) {
            this.StartDate = (e) ? moment(e[0]).format('yyyy-MM-DD') : "";
            this.EndDate = (e) ? moment(e[1]).format('yyyy-MM-DD') : "";
        },
        showModalNavbarFilter(){
            this.checkmodal()
            this.modalNavbarFilter.show();
        },
        showModalLogout(){
            this.checkmodal()
            this.modalLogout.show(); 
        },
        meLogout(){
            this.checkmodal()
            this.modalLogout.hide();
            this.logout();
        },
        async meChangePassword(){
            const me = this;

            if(me.fieldsCP.NewPassword != me.fieldsCP.ConfirmPassword){
                me.errorsCP.ConfirmPassword = ['tidak sama seperti password'];
                return;
            }

            try{
                const id = this.getSessionStorage('Id');
                const response = await me.fetchData_('user/change-password/' + id, {...this.fieldsCP}, 'PUT',true);
                
                if (response.IsSuccess) {
                    this.checkmodal()
                    this.modalChangePassword.hide();
                    me.$swal({
                        icon: 'success',
                        title: 'Yee..',
                        text: "Berhasil Mengganti Password Anda!",
                    })
                } else {
                    me.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text:(typeof(response.Message) === 'string') ? response.Message : 'Terjadi kesalahan input, mohon di check kembali',
                    })
                }
            } catch (error) {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                })
            }
        },
        checkmodal () {
            if (!this.modalLogout) this.modalLogout = new Modal("#modalLogout"); 
            if(!this.modalChangePassword) this.modalChangePassword = new Modal('#modalChangePassword');
            if (!this.modalNavbarFilter) this.modalNavbarFilter = new Modal('#modalNavbarFilter');
        },
        SetExhibition(data = {}) {
            var newFields = { ...this.fields };
            newFields.ExhibitionActiveId = data.Id;
            
            if(data.Id > 1){
                this.FilterPeriode = [data.ExhibitionStartDate, data.ExhibitionEndDate];    
                this.maxDate = data.ExhibitionEndDate;
                this.minDate = data.ExhibitionStartDate;
                this.StartDate = moment(data.ExhibitionStartDate).format('yyyy-MM-DD');
                this.EndDate = moment(data.ExhibitionEndDate).format('yyyy-MM-DD');
            }else{
                this.FilterPeriode = [moment().startOf('month'), moment().endOf('month')];
                this.maxDate = null;
                this.minDate = null;
                this.StartDate = moment().startOf('month').format('yyyy-MM-DD');
                this.EndDate = moment().endOf('month').format('yyyy-MM-DD');
            }
            
            newFields.ExhibitionActiveName = data.ExhibitionName;
            newFields.ExhibitionStartDate = data.ExhibitionStartDate
            newFields.ExhibitionEndDate = data.ExhibitionEndDate
            this.fields = newFields;
        },
        SetCampaign(data = {}) {
            var newFields = { ...this.fields };            
            newFields.CampaignActiveId = data.Id;
            newFields.CampaignActiveName = data.CampaignName;
            newFields.ExhibitionActiveId = (data.Id == 1) ? 1 : null;
            if(data.Id == 1) newFields.ExhibitionActiveName = "ALL";           
            this.apiExhib.parameter.campaignId = data.Id;
            this.maxDate = null;
            this.minDate = null;
            this.FilterPeriode = null;
            this.fields = newFields;
        },
        async showModalChangePassword(){
            this.checkmodal()
            this.modalChangePassword.show(); 
        },
        async ChangeFilterExhibition(){
            this.errors = {};
            if(!this.fields.CampaignActiveId){
                this.errors.CampaignActiveId = ['Campaign Harus dipilih!!!']
                return;
            }
            if(!this.fields.ExhibitionActiveId){
                this.errors.ExhibitionActiveId = ['Exhibition Harus dipilih!!!']
                return;
            }
            this.$swal.fire({
                title: 'Terapkan Filter',
                text:'Apakah anda yakin ingin menerapkan Filter "' + this.fields.CampaignActiveName + ' - ' + this.fields.ExhibitionActiveName +'" \n*Perubahan ini akan memuat ulang halaman ini!',
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Iya, Terapkan',
                denyButtonText: 'Tidak, Kembali',
                customClass: {
                    confirmButton: 'bg-primary',
                },
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let parsedFilter = { StartDate: this.StartDate, EndDate: this.EndDate };
                    this.isRefresh = true;

                    const campActiveId = this.getSessionStorage('CampaignActiveId');
                    const exhibActiveId = this.getSessionStorage('ExhibitionActiveId');
                    
                    if(campActiveId < 2 || exhibActiveId < 2){
                        if(!this.StartDate || !this.EndDate){
                            parsedFilter.StartDate = moment().startOf('month').format('yyyy-MM-DD');
                            parsedFilter.EndDate = moment().endOf('month').format('yyyy-MM-DD');
                        }

                        // JIKA ALL ANTARA EXHIB ATAU CAMPANGIN
                        const days = moment(parsedFilter.EndDate).diff(moment(parsedFilter.StartDate), 'days');
                        if(days >= 62){
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Untuk Exhibiton ALL atau Campaign ALL, Periode tanggal awal dan akhir tidak disarankan lebih dari 2 Bulan (62 hari)',
                            });
                            return;
                        }
                    }
                    
                    window.sessionStorage.setItem('expos-filter',JSON.stringify(parsedFilter));
                    
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    var me = this;
                    try {
                        const response = await me.fetchData_('exhibition/'+this.fields.ExhibitionActiveId, { }, 'GET', true);
                        if (!response.IsSuccess) {
                            me.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Terjadi kesalahan input, mohon di check kembali',
                            })
                        } else {

                            const expos = window.sessionStorage.getItem('expos');
                            const exposParsedData = JSON.parse(expos);

                            exposParsedData.CampaignActiveId = this.fields.CampaignActiveId;
                            exposParsedData.CampaignActiveName = this.fields.CampaignActiveName;
                            exposParsedData.ExhibitionActiveId = this.fields.ExhibitionActiveId;
                            exposParsedData.ExhibitionActiveName = this.fields.ExhibitionActiveName;
                            exposParsedData.ExhibitionStartDate = this.fields.ExhibitionStartDate;
                            exposParsedData.ExhibitionEndDate = this.fields.ExhibitionEndDate;

                            exposParsedData.Companies = response.Data.ExhibitionCompanies.map(x=>{
                                // if(x.IsHost) exposParsedData.MasterCompanyId = x.Company.Id;
                                return{
                                    "Id": x.Company.Id,
                                    "CompanyName":  x.Company.CompanyName,
                                    "CompanyCode":  x.Company.CompanyCode,
                                    "CompanyProvince": x.Company.CompanyProvince,
                                    "CompanyCity": x.Company.CompanyCity,
                                    "CompanyArea": x.Company.CompanyArea,
                                    "CompanyStatus": x.Company.CompanyStatus,
                                    "IsHost":  x.IsHost
                                }
                            });

                            window.sessionStorage.setItem('expos', JSON.stringify(exposParsedData));

                            location.href = location.origin + this.$router.currentRoute.value.path; 
                            
                        }
                    } catch (error) {
                        this.isRefresh = false;
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: error,
                        })
                    }
                }
            });
        },
    },
    mounted() {
        this.username = this.getSessionStorage('Username');
        this.usertype = this.getSessionStorage('UserType');

        const startDate = moment(this.getSessionStorage('ExhibitionStartDate'), 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YY');
        const endDate = moment(this.getSessionStorage('ExhibitionEndDate'), 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YY');
        const datePeriode = (this.getSessionStorage('ExhibitionActiveName') != 'ALL' && this.getSessionStorage('CampaignActiveName') != 'ALL' && this.getSessionStorage('ExhibitionActiveName') != 'ALL' && this.getSessionStorage('CampaignActiveName') != 'ALL') ? " - " + startDate + " - " + endDate : '';

        const CampaignName = this.getSessionStorage('CampaignActiveName');
        const ExhibitonName = this.getSessionStorage('ExhibitionActiveName');
        const Combine = (CampaignName == ExhibitonName) ? ExhibitonName : CampaignName + ' - ' + ExhibitonName;

        this.exhibitionShowCurrent = Combine + datePeriode;

    },
    beforeMount(){
        const filterData = window.sessionStorage.getItem('expos-filter'),
        campActiveId = this.getSessionStorage('CampaignActiveId'),
        exhibActiveId = this.getSessionStorage('ExhibitionActiveId'),
        compActiveId = this.getSessionStorage('MasterCompanyId'),
        exhibStartDate = this.getSessionStorage('ExhibitionStartDate'),
        exhibEndDate = this.getSessionStorage('ExhibitionEndDate'),
        isDefaultDateRange = (exhibActiveId < 2 || compActiveId < 2),
        defaultStartDate = moment().startOf('month').format('yyyy-MM-DD'),
        defaultEndDate = moment().endOf('month').format('yyyy-MM-DD');

        let isDatePeriodParamsProvided = false,
        parsedFilter = {StartDate:null, EndDate:null};

        if(filterData){
            const parsedFilterData = JSON.parse(filterData);
            if(parsedFilterData.StartDate != null && parsedFilterData.EndDate != null){
                isDatePeriodParamsProvided = true;
                this.StartDate = parsedFilterData.StartDate;
                this.EndDate = parsedFilterData.EndDate;
                this.FilterPeriode = [this.StartDate, this.EndDate];
            }
        }

        if (!isDatePeriodParamsProvided) {
            parsedFilter.StartDate = isDefaultDateRange ? defaultStartDate : moment(exhibStartDate).format('yyyy-MM-DD');
            parsedFilter.EndDate = isDefaultDateRange ? defaultEndDate : moment(exhibEndDate).format('yyyy-MM-DD');
            this.StartDate = parsedFilter.StartDate;
            this.EndDate = parsedFilter.EndDate;
            this.FilterPeriode = [this.StartDate, this.EndDate];
            window.sessionStorage.setItem('expos-filter', JSON.stringify(parsedFilter));
        }

        this.minDate = (exhibActiveId > 1) ? moment(exhibStartDate).format('yyyy-MM-DD') : null;
        this.maxDate = (exhibActiveId > 1) ? moment(exhibEndDate).format('yyyy-MM-DD') : null;

        this.fields.CampaignActiveId = campActiveId;
        this.fields.CampaignActiveName = this.getSessionStorage('CampaignActiveName');

        this.fields.ExhibitionActiveId = exhibActiveId;
        this.fields.ExhibitionActiveName = this.getSessionStorage('ExhibitionActiveName');
        
        this.fields.ExhibitionStartDate = exhibStartDate;
        this.fields.ExhibitionEndDate = exhibEndDate;
        
        this.apiExhib.parameter.campaignId = this.fields.CampaignActiveId;

        const MasterCompanyId = compActiveId;
        const foundCompany = this.getSessionStorage('Companies').find((c)=> c.Id == MasterCompanyId);
        this.CompanyCode = (foundCompany) ? foundCompany.CompanyCode + '-' : 'ALL-' 
    },
    computed: {
        currentRoutePath() {
            return this.$route;
        }
    },
}
</script>
