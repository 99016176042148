<template>
    <label :class="['mb-0 text-primary fw-bold ', title_ == '' ? 'd-none' : '']">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <div :class="(isObsecureInput) ? 'd-flex' : 'input-group' + classGroup_">
        <input 
            :type="inputType"  
            :value="modelValue" 
            :class="'form-control ' + class_ + focused + (error_ ? ' is-invalid': '')" 
            :placeholder="placeholder_" 
            :readonly="readonly_" 
            autocomplete="off" 
            @focus="focused = (!readonly_ ? ' focused is-focused': '')" 
            v-on:blur="modelValue ? '': (focused = '')"
            @input="handleInput"
            @keypress="handleKeypress"
            @keyup="$emit('keyup', $event)"
            :disabled="readonly_"
            :style="isForceUppercase ? 'text-transform: uppercase;' : ''"
        >
        <div class="bg-primary rounded-0 rounded-end align-content-center px-2" v-if="isObsecureInput" @click="toggelObsecure">
            <font-awesome-icon :icon="['fas', (inputType == 'password') ? 'eye' : 'eye-slash']" class="text-white"/>
        </div>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
        <span v-for="(error,index) in error_" :key="index" class="d-block">
            {{ error }}
        </span>
    </small>
</template>

<script>
export default {
    props: {
        classGroup_:        { type: String, default: '' },
        class_:             { type: String, default: '' },
        title_:             { type: String, default: '' },
        type_:              { type: String, default: 'text' },
        placeholder_:       { type: String },
        readonly_:          { type: Boolean, default: false },
        required_:          { type: Boolean, default: false },
        error_:             { type: Array },
        modelValue:         { type: String },
        isObsecureInput:    { type: Boolean, default: false },
        isForceUppercase:   { type: Boolean, default: true },
        isOnlyNumeric:      { type: Boolean, default: false }
    },
    emits: ['update:modelValue', 'keyup'],
    data() {
        return {
            focused: '',
            inputType: '',
        };
    },
    beforeMount() {
        if (this.modelValue) { 
            this.focused = ' focused is-focused ' 
        }
        this.inputType = this.isOnlyNumeric ? 'tel' : this.type_;
    },
    methods: {
        toggelObsecure() {
            this.inputType = (this.inputType == 'text') ? 'password' : 'text';
        },
        handleInput(event) {
            let value = event.target.value;
            if (this.isOnlyNumeric) {
                value = value.replace(/[^0-9]/g, '');
            }
            if (this.isForceUppercase) {
                value = value.toUpperCase();
            }
            this.$emit('update:modelValue', value);

            const inputElement = event.target;
            const cursorPosition = inputElement.selectionStart;

            this.$nextTick(() => {
                inputElement.setSelectionRange(cursorPosition, cursorPosition);
            });
        },
        handleKeypress(event) {
            if (this.isOnlyNumeric) {
                const charCode = (event.which) ? event.which : event.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    event.preventDefault();
                }
            }
        }
    }
}
</script>

<style>
.is-invalid {
    border: solid #dc3545 1px !important;
    border-radius: 0.375rem !important;
}
</style>