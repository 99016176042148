<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm" id="detail-table">
            <!-- <thead class="table-info"> -->
                <!-- <div class="form-check d-inline ps-0">
                        <input class="form-check-input" type="checkbox" :checked="checkAllStatus">
                    </div> -->
                <!-- <tr>
                    <th v-for="item in initialFields_" :key="item.key" class="move-this px-1 py-1 text-primary fw-bolder" style="position: relative">
                        <div>{{ item.label }}</div>
                    </th>
                </tr>
            </thead> -->
            <thead class="table-info sticky-top bg-white">
                
                    <tr>
                        <th v-for="(item, index) in headerFields_" class="move-this p-2 text-black" :key="'head-'+index+'-'+item.sort" style="position: relative">
                            
                            <slot v-if="!!$slots[item.key+'-header']" :name="item.key+'-header'" />
                            <template v-else>
                                <a class="user-select-none">{{ item.label }} &nbsp;</a>
                                <a v-if="item.sorting" class="" href="javascript:void(0)" @click="sortedItem(index)">
                                    <font-awesome-icon :icon="(item.sort == 'asc' ? ['fas', 'sort-amount-up-alt']: ['fas', 'sort-amount-up'])" />
                                </a>
                                <a v-if="item.checkbox" :class="['d-inline-block border border-1 rounded text-center text-white lh-sm',(isCheckAllitem) ? 'bg-primary':'bg-white border-dark']" @click="checkAllItem()" href="javascript:void(0)" style="width: 25px !important;height: 25px !important;">
                                    <font-awesome-icon :icon="['fas', 'check']" class="align-middle"/>
                                </a>
                            </template>
                        </th>
                    </tr>
                </thead>
            <tbody>
                <slot name="tbody"></slot>
            </tbody>
            <tfoot>
                <slot name="tfoot"></slot>
            </tfoot>
        </table>
        <small v-if="errors_" class="text-danger fst-italic">{{ errors_ }}</small>
    </div>
</template>

<script>
export default {
    props: {
        headerFields_:          { type: Array, default: () => [] },
        errors_:                 { type: [String, Array] }
    },
    data() {
        return {
            isCheckAllitem:false
        };
    },
    mounted() {
        this.moveHeader();
    },
    methods: {
        checkAllItem(){
            this.isCheckAllitem = !this.isCheckAllitem;
        },
        moveHeader() {
            var thElm;
            var startOffset;
            var divTh = document.getElementById('detail-table').offsetHeight;
                
            Array.prototype.forEach.call( document.querySelectorAll('th.move-this'), function (th) {
                var grip = document.createElement('div');
                grip.innerHTML = "&nbsp;";
                grip.classList.add('move-header');
                grip.style.height = divTh + 'px';
                grip.addEventListener('mousedown', function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                    // stop event to be forwarded to parent component
                    e.stopPropagation();
                });
                grip.addEventListener('click', function (e) {
                    // stop event to be forwarded to parent component
                    e.stopPropagation();
                });
                th.appendChild(grip);

            });

            document.addEventListener('mousemove', function (e) {
                if (thElm) {
                    thElm.style['min-width'] = startOffset + e.pageX + 'px';
                }
            });

            document.addEventListener('mouseup', function () {
                thElm = undefined;
            });
        },
    }
}
</script>