<template>
    <div class="container-fluid bg-white gradient-border-top rounded-bottom shadow">
        <div class="row g-0">
            <div class="col-12 text-center py-2 g-0 user-select-none">
                <span class="text-secondary" style="font-size: 0.9rem !important;">GMB &copy;2024 </span>
                <!-- <font-awesome-icon :icon="['fas', 'code']" class="me-2"/> -->
            </div>
        </div>
    </div>
    <!-- SON WAS HERE :) -->
</template>

<script>
export default {

}
</script>

<style>

</style>